<template>
  <div>
    <general-table
      :api-url="APIURL"
      :columns="columns"
      :delete-content="false"
      :block-content="false"
      :edit-content="false"
      :view-content="false"
      :type="type"
      :selectable="false"
    >
      <template #cell(winner_name)="data">
        <b-avatar
          size="50"
          :src="data.item.image"
        />

        <router-link
          class="mx-1"
          :to="{ name: 'show-user-customer', params: { id: data.item.winner_id } }"
        >
          {{ data.item.winner_name }}
        </router-link>
      </template>
    </general-table>

  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'admin/winners',
      type: 'page',
      urlType: 'winners',
      columns: [
        { key: 'winner_name', label: 'Winner' },
        { key: 'product_name', label: 'Product name' },
        { key: 'gift_name', label: 'Gift name' },
        { key: 'ticket_code', label: 'Ticket' },
      ],
    }
  },
}
</script>

    <style>

    </style>
